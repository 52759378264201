.blog-section {
    margin: 20px 0;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
    color: #333;
    text-align: left;
    width: 75%;
    margin: 20px auto;

  }
  
  .blog-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
  
  .blog-header-left {
    display: flex;
    align-items: center;
    width: 85%;

  }
  .blog-title-section{
    display: flex;
    flex-direction: column;
    width: 75%;
    gap: 2px;
    
  }
  
  .blog-subtitle {
    font-size: 16px;
    color: #a7185d;; /* Example highlight color */
    margin: 0;
  }
  
  .blog-title {
    font-size: 36px;
    font-weight: 600;
    margin: 0 0 0px 0;
  }
  
  .blog-intro {
    font-size: 16px;
    line-height: 1.7;
    margin: 0;
    color: #868686;
  }
  
  .blog-header-right {
    margin-top: 20px;
  }
  
  .view-more-button {
    background-color: #a7185d;;
    color: #fff;
    border: none;
    padding: 15px 30px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;

  }
  
  .view-more-button:hover {
    background-color: #a30065;
  }
  
  .blog-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }
  
  .blog-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    width: calc(33.333% - 13.333px); /* 3 cards per row */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  
  .blog-card-image img {
    width: 100%;
    height: 280px;
    object-fit: cover;    
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  
  .blog-card-content {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .blog-card-title {
    font-size: 16px;
    margin: 0 0 10px 0;
    font-weight: 600;
  }
  
  .blog-card-excerpt {
    font-size: 15px;
    line-height: 1.5;
    flex: 1;
    margin: 0 0 20px 0;
    color: rgb(164, 164, 164);
    letter-spacing: 0.04rem;
  }
  
  .blog-card-button {
    background-color: #a7185d;;
    color: #fff;
    border: none;
    padding: 15px 20px;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-start;

    
  }
  
  .blog-card-button:hover {
    background-color: #a30065;
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .blog-card {
      width: calc(50% - 10px);
    }
  }
  
  @media (max-width: 600px) {
    .blog-section {
      padding: 40px 0px;
      
      width: 95%;
  
    }
    .blog-title-section {
   align-items: center;
      width: 100%;
  }
    .blog-header-left {
     flex-direction: column;
      width:100%;
      gap: 12px;
  }
    .blog-intro{
      text-align: center;

    }

    .blog-header {
      flex-direction: column;
      align-items: center;
    }
    
    .blog-header-right {
      margin: 20px 0 0 0;
    }
  
    .blog-card {
      width: 100%;
    }
  }
  